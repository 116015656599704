



























































































































































































































































import { defineComponent } from "@vue/composition-api";
import moment from "moment";
import { mapActions as mapPiniaActions, mapState, mapStores } from "pinia";
import { mapActions } from "vuex";
import { getRelativeOccupancy } from "@/lib/getRelativeOccupancy";
import { logger } from "@/logger";
import { useAppStore } from "@/pinia/app";
import { useLocationStore } from "@/pinia/location";
import { usePopupStore } from "@/pinia/popup";

interface State {
	loading: boolean;
	lesId?: number;
	aanwezigheidslijst: Array<{
		lidNaam: string;
		lidId: number;
		deelnames: unknown;
		status: string;
	}>;
	ingediend: boolean;
	les?: {
		groepsles_naam: string;
		status: string;
		item_type: string;
		datum: string;
		tijd: string;
		zaal_naam: string;
		bezet: number;
		plekken: number;
		trainer_naam: string;
		les_id: number;
	};
	currentTime: moment.Moment;
	interval?: ReturnType<typeof setInterval>;
	allowSubmissionAfterMinutes: number;
	showCancellations: boolean;
}

export default defineComponent({
	data(): State {
		return {
			loading: false,
			lesId: undefined,
			aanwezigheidslijst: [],
			ingediend: false,
			les: undefined,
			currentTime: moment(),
			interval: undefined,
			allowSubmissionAfterMinutes: 15,
			showCancellations: false,
		};
	},
	computed: {
		...mapStores(usePopupStore),
		...mapState(useLocationStore, {
			location: "location",
			locations: "locationsSortedByName",
		}),
		...mapState(useAppStore, { occupancyFormat: "occupancyFormat" }),
		cancelable() {
			const les = this.les as State["les"];
			const currentTime = this.currentTime as State["currentTime"];

			if (!les) {
				return false;
			}

			const cancelableBefore = moment(`${les.datum} ${les.tijd}`, "DD-MM-YYYY H:mm").subtract(
				60,
				"minutes",
			);
			return currentTime < cancelableBefore;
		},
		started() {
			const les = this.les as State["les"];
			const currentTime = this.currentTime as State["currentTime"];
			const allowSubmissionAfterMinutes = this
				.allowSubmissionAfterMinutes as State["allowSubmissionAfterMinutes"];

			if (!les) {
				return false;
			}

			const startTime = moment(`${les.datum} ${les.tijd}`, "DD-MM-YYYY H:mm").add(
				allowSubmissionAfterMinutes,
				"minutes",
			);
			return currentTime > startTime;
		},
		sortedAanwezigheidslijst() {
			const aanwezigheidslijst = this.aanwezigheidslijst as State["aanwezigheidslijst"];

			return aanwezigheidslijst
				.filter(({ status }) => status !== "afgemeld")
				.sort((a, b) => {
					if (a.lidNaam < b.lidNaam) return -1;
					if (a.lidNaam > b.lidNaam) return 1;
					return 0;
				})
				.sort((a, b) => {
					if (a.status === "afgemeld") return 1;
					if (b.status === "afgemeld") return -1;
					if (a.deelnames === 0) return -1;
					if (b.deelnames === 0) return 1;
					return 0;
				});
		},
		cancellations() {
			const aanwezigheidslijst = this.aanwezigheidslijst as State["aanwezigheidslijst"];

			return aanwezigheidslijst
				.filter(({ status }) => status === "afgemeld")
				.sort((a, b) => {
					if (a.lidNaam < b.lidNaam) return -1;
					if (a.lidNaam > b.lidNaam) return 1;
					return 0;
				})
				.sort((a, b) => {
					if (a.deelnames === 0) return -1;
					if (b.deelnames === 0) return 1;
					return 0;
				});
		},
		isInFuture() {
			const les = this.les as State["les"];
			const currentTime = this.currentTime as State["currentTime"];

			if (!les) {
				return false;
			}

			const startTime = moment(`${les.datum} ${les.tijd}`, "DD-MM-YYYY H:mm");
			return currentTime < startTime;
		},
	},
	watch: {
		$route: "getAndSet",
	},
	async created() {
		this.loading = true;

		await this.getAndSet();

		this.ingediend = false;
		this.aanwezigheidslijst = [];
		this.interval = setInterval(() => {
			this.currentTime = moment();
		}, 5000);

		await this.initLocation();
		await this.getAndSet();
		await this.populateLes();
		await this.populateAanwezigheidslijst();

		this.loading = false;
	},
	async destroyed() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	},
	async beforeDestroy() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	},
	methods: {
		...mapPiniaActions(useLocationStore, {
			initLocation: "init",
		}),
		...mapActions("modal", { openModal: "openModal" }),
		...mapActions("tijdsloten", {
			getAanwezigheidsLijst: "getAanwezigheidsLijst",
			postAanwezigheidsLijst: "postAanwezigheidsLijst",
			postAanwezigheidsLijstDefinitief: "postAanwezigheidsLijstDefinitief",
			getFitnessBlok: "getFitnessBlok",
		}),
		async getAndSet() {
			const { id } = this.$route.params;

			if (id) {
				this.lesId = Number(id);
				await this.populateLes();
			}
		},
		async populateLes() {
			this.les = await this.getFitnessBlok(this.lesId);
		},
		async populateAanwezigheidslijst() {
			const data = await this.getAanwezigheidsLijst(this.lesId);

			this.ingediend = data.ingediend;
			this.aanwezigheidslijst = data.lijst;
		},
		cancelLessonPopup() {
			this.openModal({
				name: "les-annuleren",
				data: { lesId: this.lesId, les: this.les, type: "fitness blok" },
				callback: () => {
					this.populateAanwezigheidslijst();
					this.populateLes();
				},
			});
		},
		lijstIndienenPopup() {
			this.popupStore.open({
				title: "Let op!",
				body: `<p>Het beste tijdstip voor het indienen is <b>NA</b> de les. Er kunnen bijvoorbeeld mensen te laat komen.<br/>Wijzigen na indienen is niet meer mogelijk.</p>
          <p>Leden die niet aanwezig zijn:</p>
          <ul>
              <li> krijgen een notificatie in de app.</li>
              <li> krijgen straf-tijd (niet meer kunnen reserveren de komende 12 uur).</li>
              <li> worden automatisch uitgeschreven als ze een herhalende les hebben.</li>
          </ul>`,
				buttons: {
					cancel: "Annuleren",
					confirm: "Indienen",
				},
				callback: () => this.lijstIndienen(),
			});
		},
		lijstIndienen() {
			const data = {
				lesId: this.lesId,
				// clean up lijst for post
				lijst: this.aanwezigheidslijst.map((lid) => {
					if (lid.status === "aangemeld") lid.status = "geweest";
					delete lid.deelnames;
					return lid;
				}),
			};
			this.postAanwezigheidsLijst(data)
				.then((res) => {
					if (res.error) {
						logger.error("error post aanwezigheidslijst", res.error);
					} else {
						this.lijstDefinitiefIndienen();
					}
				})
				.catch((error) => {
					const message = error;
					this.popupStore.showError(
						`Er ging iets mis bij het indienen van de lijst: ${message}.<br/>Probeer het later nog eens.`,
					);
				});
		},
		lijstDefinitiefIndienen() {
			this.postAanwezigheidsLijstDefinitief(this.lesId)
				.then(() => {
					this.ingediend = true;
					this.populateAanwezigheidslijst();
				})
				.catch((err) => {
					logger.error(err);
				});
		},
		// changeAanwezig(lidId, aanwezig) {},
		// cancelLessonPopup() {
		//   const this = this;
		//   this.openModal({
		//     name: 'les-annuleren',
		//     data: { lesId: this.lesId, les: this.les },
		//     callback() {
		//       this.populateAanwezigheidslijst();
		//       this.populateLes();
		//     }
		//   });
		// },
		openReserveerModal() {
			this.openModal({
				name: "fitness-blok-aanmelden",
				data: { les: this.les, search: true },
				callback: () => {
					this.populateAanwezigheidslijst();
					this.populateLes();
				},
			});
		},
		// openUndoLessonCancellationModal() {
		//   this.openModal({
		//     name: 'undo-lesson-cancellation',
		//     data: {
		//       lesson: {
		//         id: this.les.les_id
		//       }
		//     },
		//     callback: async () => {
		//       this.les = await this.getFitnessBlok(this.lesId);
		//     }
		//   });
		// },
		openAfmeldenModal(lid: unknown) {
			this.openModal({
				name: "fitness-blok-afmelden",
				data: { les: this.les, lid },
				callback: () => {
					this.populateAanwezigheidslijst();
				},
			});
		},
		async aanwezigChange(bool: boolean, lid: { lidId: number; status: string }) {
			lid.status = bool ? "geweest" : "niet_geweest";
			await this.postAanwezigheidsLijst({
				lesId: this.les?.les_id,
				lijst: [
					{
						lidId: lid.lidId,
						status: bool ? "aangemeld" : "niet_geweest",
					},
				],
				ingediend: false,
			});

			this.populateLes();
		},
		onOpenSendMessageModal() {
			this.openModal({
				name: "send-message",
				data: {
					les: this.les,
				},
			});
		},
		getRelativeOccupancy,
	},
});
